
<template>
    <div class="w-full py-6">
        <div class="flex">
            <div class="w-1/4" v-for="(item, index) in wizardStepsBar" :key="index">
                <div class="relative mb-2">

                    <div v-if="index != 0" class="absolute flex align-center items-center align-middle content-center" style="width: calc(100% - 2.5rem - 1rem); top: 50%; transform: translate(-50%, -50%)">
                        <div class="w-full bg-gray-200 rounded-full items-center align-middle align-center flex-1">
                            <div class="w-0 bg-gray-400 py-1 rounded-full" :class="{'width-50': item.active, 'width-100': item.finished,}"></div>
                        </div>
                    </div>

                    <div class="w-10 h-10 mx-auto bg-gray-800 rounded-full text-lg text-white flex items-center" :class="{'bg-gray-800': item.active, 'bg-white bg-white border-2 border-gray-200': !item.active}">
                        <span class="text-center w-full" :class="{'text-white': item.active, 'text-gray-600': !item.active}">
                           <span v-html="item.svg"></span>
                        </span>
                    </div>
                </div>

                <div class="text-xs text-center md:text-sm font-medium uppercase">{{ item.text }}</div>
            </div>
        </div>
        
    </div>
</template>
<script>

export default {
  data () {
    return {
        data: {
            step1: {
                svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /></svg>',
                text : 'MES DATES',
                active: true
            },
            step2: {
                svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>',
                text : 'LISTE DES PRODUITS',
                active: true
            },
            step3: {
                svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" /></svg>',
                text : 'CONNEXION',
                active: true
            },
            step4: {
                svg : '<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" /></svg>',
                text : 'PANIER',
                active: false
            },
        }
    }
  },
  props: {
    step: {
      required: true
    },
  },
  watch: {
    step (newValue, oldValue) {
        console.log('step', newValue)
        this.$store.commit('eCommerce/UPDATE_WIZARD_STEPS_BAR', newValue)
    }
  },
  computed: {
    wizardStepsBar () {
      return this.$store.state.eCommerce.wizardStepsBar
    },
  },
  methods: {

  },

}

</script>

<style scoped>

.bg-primary, .vs-button-primary.vs-button-filled, a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus{
    --tw-bg-opacity: 1;
    background-color: rgba(var(--vs-primary), 1)!important;
}

.width-50{
    width: 50%
}

.width-100{
    width: 100%
}
</style>

